<template>
    <v-card>
        <v-card-title>
            <span class="headline">{{ formTitle }}</span>
        </v-card-title>

        <v-card-text>
            <v-container>
                <v-row>
                    <v-col cols="12">
                            <v-text-field
                                v-model="item.label"
                                :label="trans('fields.common.label')"
                                :error-messages="errors.label"
                                @input="clearError('label')"
                            ></v-text-field>
                    </v-col>
                </v-row>
            </v-container>
        </v-card-text>

        <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn text @click="$emit('close')">{{ trans('buttons.common.cancel') }}</v-btn>
            <v-btn v-if="itemId" color="primary" outlined @click="save">{{ trans('buttons.common.save') }}</v-btn>
            <v-btn v-else color="primary" outlined @click="save">{{ trans('buttons.common.create') }}</v-btn>
        </v-card-actions>
    </v-card>
</template>

<script>
    import formValidate from "@/mixins/formValidate";
    export default {
        name: "CpaNetworkForm",
        mixins: [formValidate],
        props: {
            itemId: {
                type: [Number, undefined],
            },
        },
        data(){
            return {
                showRoute: 'user.cpaNetworks.show',
                storeRoute: 'user.cpaNetworks.store',
                updateRoute: 'user.cpaNetworks.update',
                item: {
                    id: null,
                    label: '',
                },
                options: {},
            }
        },
        created() {
            this.load();
        },
        methods: {
            load(){
                this.loadOptions();
                if (this.itemId){
                    axios.get(this.route(this.showRoute, this.itemId)).then( response => {
                        this.item = response.data;
                    }).catch( err => {
                        console.log(err);
                    })
                }
            },
            loadOptions(){
            },
            save() {
                if (!this.item.id) {
                    axios.post(this.route(this.storeRoute), this.item).then((response) => {
                        console.log('save success');
                        this.$emit('saved', response.data.id);
                        this.$store.dispatch('showSnack', {text: this.trans('pages.app.saveSuccess'), type: 'success'});
                    }).catch( (err) => {
                        this.errors = err.response.data.errors;
                        console.log(err.response.data);
                        this.$store.dispatch('showSnack', {text: this.trans('pages.app.saveError'), type: 'error'});
                    })
                } else {
                    axios.post(this.route(this.updateRoute, this.itemId), this.item).then((response) => {
                        console.log('save success');
                        this.$emit('saved', response.data.id);
                        this.$store.dispatch('showSnack', {text: this.trans('pages.app.saveSuccess'), type: 'success'});
                    }).catch( (err) => {
                        this.errors = err.response.data.errors;
                        console.log(err.response.data);
                        this.$store.dispatch('showSnack', {text: this.trans('pages.app.saveError'), type: 'error'});
                    })
                }
            },
        },
        computed: {
            formTitle() {
                return !this.itemId
                    ? this.trans('pages.cpaNetwork.newDialogTitle')
                    : this.trans('pages.cpaNetwork.editDialogTitle');
            },
        }
    }
</script>
